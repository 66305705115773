<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>単語を覚える</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-item>
        <ion-label>ジャンル</ion-label>
        <ion-select
          value="brown"
          ok-text="Select"
          cancel-text="Cancel"
          v-model="janre"
          @ionChange="this.changeJanre($event.target.value)"
        >
          <ion-select-option
            v-for="(j, index) in janres"
            :key="index"
            class="center-card"
          >
            {{ j.name }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-item class="align">
        <ion-label>タップすると音声が流れます</ion-label>
      </ion-item>
      <ion-card
        @click="playAudio"
        @touchstart="swipeEvent(0, $event)"
        @touchend="swipeEvent(1, $event)"
        @touchmove="swipeEvent(2, $event)"
        id="card"
      >
        <ion-card-content class="align">
          <p>{{ target.japanese }}</p>
        </ion-card-content>
        <ion-card-content class="align">
          <p>{{ target.english }}</p>
        </ion-card-content>
        <ion-card-content class="align">
          <p v-html="target.string" class="string"></p>
        </ion-card-content>
        <ion-card-content class="align">
          <p>{{ target.pronunciation }}</p></ion-card-content
        >
      </ion-card>
      <ion-grid>
        <ion-row>
          <ion-col class="right">
            <ion-button color="light" @click="prev()">Prev</ion-button>
          </ion-col>
          <ion-col>
            <ion-button color="dark" @click="next()">Next</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      {{ debug }}
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/vue";

export default {
  name: "Tab2",
  created() {
    this.target = {
      english: "Please.",
      japanese: "お願いします。",
      Georgian: "gg_bc_02_01",
      pronunciation: "gtKhovt",
      string: "&#4306;&#4311;&#4334;&#4317;&#4309;&#4311;&#46;",
    };
    this.words = require(`@/assets/json/everyday.json`);
  },
  methods: {
    swipeEvent(ud, e) {
      const touch = e.touches[0] || e.changedTouches[0];
      const card = document.querySelector("#card");
      if (this.startX === null) {
        this.startX = touch.clientX;
      } else {
        card.style.position = "relative";
        card.style.left = `${(touch.clientX - this.startX) * 1.5}px`;
      }
      if (ud === 1) {
        if (Math.abs(touch.clientX - this.startX) > 100) {
          if (touch.clientX - this.startX < 0) {
            this.prev();
          } else {
            this.next();
          }
        }
        this.startX = null;
        card.style.position = "relative";
        card.style.left = `0px`;
      }
    },
    changeJanre(janreWord) {
      this.janre = this.janres.filter((v) => {
        return v.name === janreWord;
      })[0].file;
      this.words = require(`@/assets/json/${this.janre}.json`);
      this.targetNum = 0;
      this.target = this.words[this.targetNum];
    },
    playAudio() {
      const file = this.target.Georgian;
      const music = new Audio(require(`@/assets/sounds/${file}.mp3`));
      music.play();
    },
    prev() {
      this.targetNum = this.targetNum - 1;
      if (this.targetNum < 0) {
        this.targetNum = this.words.length - 1;
      }
      this.target = this.words[this.targetNum];
    },
    next() {
      this.targetNum = this.targetNum + 1;
      if (this.targetNum > this.words.length - 1) {
        this.targetNum = 0;
      }
      this.target = this.words[this.targetNum];
    },
  },
  data() {
    return {
      janre: "よくつかうもの",
      targetNum: 0,
      target: null,
      debug: null,
      startX: null,
      janres: [
        {
          name: "あいさつ",
          file: "greetings",
        },
        {
          name: "質問",
          file: "interrogation",
        },
        {
          name: "数字",
          file: "number",
        },
        {
          name: "時間",
          file: "weektime",
        },
        {
          name: "行き方",
          file: "directions",
        },
        {
          name: "場所",
          file: "location",
        },
        {
          name: "色・形容詞",
          file: "descriptions",
        },
        {
          name: "緊急時",
          file: "emrgency",
        },
        {
          name: "食べ物",
          file: "food",
        },
        {
          name: "燃料・メンテナンス",
          file: "fuel",
        },
        {
          name: "医療",
          file: "medical-gemeral",
        },
        {
          name: "宿泊",
          file: "lodging",
        },
        {
          name: "職業",
          file: "occupations",
        },
        {
          name: "習慣",
          file: "custom",
        },
        {
          name: "親戚",
          file: "relatives",
        },
        {
          name: "天気",
          file: "weather",
        },
        {
          name: "身体",
          file: "bodyparts",
        },
        {
          name: "よくつかうもの",
          file: "everyday",
        },
      ],
    };
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
  },
};
</script>
<style scoped>
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.string {
  font-size: 200%;
  font-weight: bold;
}
.align {
  text-align: center;
}
</style>